<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}儿童隐私政策</h3>
      <p style="text-align: right">公布时间：2024年【8】月【2】日</p>
      <p style="text-align: right">生效时间：2024年【8】月【2】日</p>
      <p style="text-align: right">版本：第【四】版</p>
      <section>
        <h5>概述</h5>
        <p>
          {{ companyName }}（以下简称“{{ companyAlias }}”“{{
            appName
          }}”或“我们”）深知儿童（未满十四周岁）个人信息的重要性，并会全力保护使用我们产品或服务的儿童的个人信息安全。我们致力于维持监护人（以下简称“您”）对我们的信任，并将恪守以下原则，保护儿童的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、公开透明原则、确保安全原则、主体参与原则等。同时，开心音符承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护儿童的个人信息。
        </p>
        <p>
          使用{{
            appName
          }}产品和/或服务前，请儿童及监护人务必仔细阅读并透彻理解本政策，特别是以粗体/下划线标识/斜体的条款应重点阅读。
        </p>
        <p class="bold">
          本软件属于音乐电台类应用程序，基本业务功能为音频播放。如您拒绝同意本政策，我们将为儿童提供音频播放的基本业务功能。您可通过“访客模式”进入。在基本业务功能模式下，我们不会收集可识别儿童个人的身份信息，但为保障音频播放基本功能的安全运行，我们需要收集您的必要个人信息。具体包括：为了实现内容播放，我们需要通过北京火山引擎科技有限公司的火山点播SDK收集操作系统api版本、CPU信息、IDFV、运营商信息、设备品牌、设备型号、操作系统、系统时区、应用版本、应用发布渠道。在基本业务功能模式下，我们无法将这些信息与儿童的身份结合或用于反映儿童的活动情况。
        </p>

        <p class="bold">
          在儿童使用相应的具体功能服务前，我们不会收集使用对应的必要个人信息。同时，在涉及调用个人信息相关权限时，我们还会通过系统弹窗另行征得您的单独同意。您不同意开启权限，不会影响其他非相关功能服务的正常使用。
        </p>
      </section>
      <section>
        <h5>儿童特别说明：</h5>
        <p class="bold">
          如果您是不满十四周岁的儿童，请和您的监护人一起仔细阅读并透彻理解本政策，并征得您的监护人同意后，使用我们的产品、服务或向我们提供个人信息。
        </p>
      </section>
      <section>
        <h5>儿童监护人特别说明：</h5>
        <p class="bold">
          如果您是儿童用户的监护人，则儿童应在您的同意和指导下使用我们的服务和/或提供个人信息。如果您不同意本政策任何内容，您应要求您监护的儿童立即停止使用我们的产品和/或服务。<span>除非另有说明，不同意本政策或其更新（我们将及时通知您此类更新）可能会影响您监护的儿童正常使用或继续正常使用我们的产品和/或服务。</span>本政策进行更新后，我们会及时在“快音”平台发布最新版本，以便您及时查阅。
        </p>
      </section>
      <section>
        <p>
          如对本政策内容有任何疑问、意见或建议，您可通过登录“快音”平台内的“设置-联系我们”页面或根据本政策第十条“如何联系我们”载明联系方式与我们联系。
        </p>
        <p class="bold">
          除本隐私政策外，在特定场景下，我们将以及时告知的方式（包括但不限于更新本隐私政策、重新签署文件、页面提示、弹窗、站内信、邮件、网站公告或其他便于您获知的方式）另行向您详细说明对应信息的处理目的、方式、范围等规则。
        </p>
        <p>本政策将帮助您了解以下内容:</p>
        <ul>
          <li>一、本政策的适用范围及个人信息处理依据</li>
          <li>二、我们如何收集和使用儿童的个人信息</li>
          <li>三、我们如何使用Cookie和同类技术</li>
          <li>四、我们如何存储及保护儿童的个人信息</li>
          <li>五、我们如何委托处理、共享、转让、公开披露儿童的个人信息</li>
          <li>六、我们如何保护儿童的个人信息</li>
          <li>七、儿童及监护人的权利</li>
          <li>八、儿童的个人信息如何在全球范围转移</li>
          <li>九、本政策如何更新</li>
          <li>十、如何联系我们</li>
          <li>十一、争议解决</li>
        </ul>
      </section>
      <section>
        <h5>相关术语及定义：</h5>
        <ul>
          <li>
            1.用户或您：指“快音”平台的注册用户；若“快音”平台产品或服务的实际使用人为注册用户的被监护人，则该等实际使用人也属于本协议项下的“您”“用户”或“您所监护的儿童”。
          </li>
          <li>
            2.“快音”平台：指{{
              companyName
            }}及其关联方开发、运营的互联网音乐平台，包括但不限于标注名称为快音、快音悦的网页、APP客户端、公众号、小程序等。
          </li>
          <li>3.{{ companyAlias }}：指{{ appName }}产品和/或服务的运营方{{ companyName }}及其关联公司。</li>
          <li>
            4.关联公司：指相互之间存在关联关系，关联关系是指{{
              companyAlias
            }}控股股东、实际控制人、董事、监事、高级管理人员与其直接或者间接控制的企业之间的关系，以及可能导致{{
              appName
            }}利益转移的其他关系。
          </li>
          <li>
            5.{{ appName }}产品和/或服务：指{{
              companyName
            }}及其关联公司通过“快音”平台向您提供的产品或服务，内容包括但不限于搜索、试听、观看、播放、缓存、下载及管理音频或视频作品等基础服务，收藏、关注、评论、分享、发布信息内容、直播、K歌、手机铃声优化等交互服务，以及充值、打赏等增值服务。个人信息：是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
          </li>
          <li>
            6.个人信息：是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
          </li>
          <li>
            7.敏感个人信息：是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。
          </li>
          <li>8.个人信息的处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</li>
          <li>
            9.收集：指获得对个人信息的控制权的行为，包括由个人信息主体主动提供、通过与个人信息主体交互或记录个人信息主体行为等自动采集，以及通过共享、转让、搜集公开信息间接获取等方式。
          </li>
          <li>10.删除：在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</li>
          <li>11.公开披露：向社会或不特定人群发布信息的行为。</li>
          <li>12.共享：个人信息控制者向其他控制者提供个人信息，且双方分别对个人信息拥有独立控制权的过程。</li>
          <li>
            13.匿名化：通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。（注：个人信息经匿名化处理后所得的信息不属于个人信息）
          </li>
          <li>
            14.去标识化：通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联个人信息主体的过程。
          </li>
        </ul>
      </section>
      <section>
        <h5>一、本政策的适用范围和个人信息处理依据</h5>
        <p class="bold">1.适用范围</p>
        <p>
          本政策适用于{{
            companyAlias
          }}提供的产品和/或服务。儿童用户访问或登录相关客户端使用我们提供的产品和/或服务，均适用本政策。
        </p>
        <p>
          需要提醒您注意，本政策仅适用于我们所收集的信息，不适用于通过我们的产品和/或服务而接入的第三方产品和/或服务（包括任何第三方网站）收集的信息以及通过在我们产品和/或服务中进行广告服务的其他公司或机构所收集的信息。
        </p>
        <p class="bold">2.个人信息处理依据</p>
        <p class="bold">
          除如下情形外，我们对您所监护儿童的个人信息的处理均应取得您明确同意（包含依据有关法律、行政法规规定应当取得您的单独同意或者书面同意）：
        </p>
        <ul>
          <li>
            （1）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
          </li>
          <li>（2）为履行法定职责或者法定义务所必需；</li>
          <li>（3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</li>
          <li>（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</li>
          <li>（5）依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</li>
          <li>（6）法律、行政法规规定的其他情形。</li>
        </ul>
      </section>
      <section>
        <h5>二、我们如何收集和使用儿童的个人信息</h5>
        <ul>
          <li class="bold">
            （一）我们没有专门针对儿童提供产品或服务，在用户使用我们的大部分产品或服务时，我们没有要求用户必须进行实名认证。因此，我们主要依靠用户在使用特定产品或服务时进行的实名认证或是否选择“青少年模式”来判断用户是否属于儿童。在用户没有进行实名认证或没有选择“青少年模式”前，我们将按照<a
              :href="privacyUrl"
              >《{{ appName }}隐私政策》</a
            >规定对用户个人信息进行保护。
          </li>
          <li>
            （二）当我们识别出用户为儿童时，我们可能会收集监护人的联系方式<span class="bold"
              >（手机号码、电子邮箱）</span
            >，并联系监护人以验证其与儿童的监护关系。
          </li>
          <li>
            （三）当用户为儿童时，我们也将依据有关法律法规规定，提示儿童应在获得监护人同意之后才允许儿童继续使用服务。
          </li>
          <li>
            （四）请您知悉，根据有关法律法规的规定，我们不会向儿童用户（我们已识别为儿童用户时）提供直播、打赏、充值和消费、充值会员以及虚拟资产等服务，还会对儿童用户使用产品进行使用时长限制和使用时段限制（具体青少年模式下不能使用的功能或服务、有关使用限制，我们将通过APP特定页面告知用户）。
          </li>
          <li>
            （五）当儿童使用我们的服务时，我们需要收集和使用儿童如下个人信息，您也可以通过<a :href="PersonalInfoListUrl"
              >《个人信息收集清单》</a
            >快速了解我们如何收集儿童的个人信息。
            <p></p>
            <p>
              1.<span class="bold">用户注册。</span
              >儿童通过第三方账号（QQ账号、微信账号或苹果账号）授权共享账号信息并申请注册成为我们的会员时，我们会收集儿童的<span
                class="bold"
                >头像、昵称。</span
              >若您所监护的儿童拒绝向我们提供前述信息，将无法注册用户账号。注册账号后，您可自主填写帐号中的性别、生日、简介相关帐号信息。
            </p>
            “快音”平台的各关联版本均使用统一的快音账号服务。“快音”平台关联版本包括但不限于快音、快音悦以及我们推出的其他快音版本。您可以使用快音账号便捷地登录、使用{{
              appName
            }}关联版本，以使用对应版本的服务或功能。为了在“快音”平台各关联版本中提供一致的服务体验，我们会同步“快音”平台各关联版中的账号和内容，其中可能包括：同步您的账号信息、关注、粉丝信息、收藏信息、喜欢列表、音乐黑名单列表、歌单信息；同步您使用此账发布的内容；同步您授权的其他信息。
            <p>
              2.<span class="bold">现金提现和福利领取。</span
              >“快音”平台面向用户推出了一系列的现金奖励活动和金币领取活动。当儿童用户在使用“快音”平台进行音视频作品分享、邀请好友使用“快音”平台、关注{{
                appName
              }}官方微信公众号操作时，将依据“快音”平台届时实施的活动规则获得现金奖励，还可通过参与听歌、签到、喝水、步数、观看视频、偷步数、浇水、下载第三方APP方式赚取金币，可将获得的金币兑换成现金。我们会通过网络请求进行金币发放，在此过程中,我们可能会使用<span
                class="bold"
                >设备ID(deviceID)和开放匿名设备标识符(OAID)信息,这些信息均为前期已缓存的数据,不会频繁获取。</span
              >金币发放场景中,我们可能会收集和使用您的<span class="bold">账户信息、设备信息、使用行为数据</span
              >以确保金币发放的准确性和公平性。当儿童用户选择通过“快音”平台（IOS版需要通过“{{
                appName
              }}娱乐平台”微信公众号进行操作）将现金提现至微信支付账号时，{{ companyAlias }}合作的<span class="bold"
                >林州薪云信息科技有限公司</span
              >出于账户安全管理以及提现功能实现之必要将<span class="bold"
                >收集接收提现资金的微信账户所绑定的手机号码及对应的真实姓名；</span
              >当儿童用户选择通过“快音”平台将现金提现至支付宝账号时，{{ companyAlias }}合作的<span class="bold"
                >林州薪云信息科技有限公司</span
              >出于账户安全管理以及提现功能实现之必要将收集<span class="bold"
                >接收提现资金的支付号账号及对应的真实姓名。</span
              >若您所监护的儿童拒绝提供前述信息，将无法使用现金提现功能。
            </p>
            <p>
              3.<span class="bold">个性化推荐。</span
              >为了便于向儿童提供符合其个性偏好的歌曲作品内容，我们需要收集儿童的<span class="bold"
                >性别、年龄、地区、点击次数、关注对象、收藏内容、搜索内容、浏览内容、分享内容、评论内容、点赞（喜欢）发布内容及次数、互动交流、反馈浏览使用行为、浏览和播放时长、本地音频文件信息、提现金额、设备标识信息、设备厂商和型号、系统版本以向您所监护儿童进行个性化推荐。</span
              >以下将详细阐述我们实现个性化推荐的规则以及您对个性化推荐功能进行管理的方式。
            </p>
            <ul>
              <li>
                （1）我们提供个性化推荐服务的范围包括“快音”平台首页（包括推荐频道、最火频道、DJ频道、抖音频道、整曲频道、伤感频道）、视频页、电台页（精品页）、曲库页。
              </li>
              <li>
                （2）我们会根据您在使用“快音”平台过程中的内容浏览相关行为对推荐模型进行实时反馈，不断调整优化推荐结果，更好地向您提供优质内容。
              </li>
              <li>
                （3）<span class="bold"
                  >我们在“快音”平台中设有个性化推荐自主管理功能，您可以通过【我的】—【设置】—【个性化推荐】管理个性化推荐功能，个性化推荐关闭后，我们将向您推荐不针对您个人偏好的内容。</span
                >您还可以通过删除播放历史、删除关注以降低推荐内容。但由于部分定向推送内容是存在于您的个人资产中，参考您收藏的个性化推荐歌单，我们不能代替您直接处置您的个人资产，因此需要您手动取消收藏或删除对应资产来退出该个性化推荐。
              </li>
              <li>
                （4）关闭个性化推荐并不会影响您正常使用“快音”平台的其他功能，您可以根据自身的喜好和判断随时决定开启或关闭该功能。
              </li>
            </ul>
            <p>
              4.<span class="bold">AI写歌。</span
              >当儿童使用快音AI写歌功能时，儿童可以通过对话框输入歌曲生成的指令（参考：写一首歌给XXX，主题为：XXXXX）、选择曲风和演唱歌手。我们会自动接收、记录儿童自愿输入的指令信息和选择的曲风信息和演唱歌手信息，并使用这些信息向儿童输出内容。
              <span class="bold"
                >请您注意，儿童是否在指令中提供个人信息、提供何种个人信息完全由您或儿童个人决定，请您务必确认儿童在您同意和指导下使用本功能。如您拒绝我们收集和处理儿童的个人信息，请您谨慎在指令中输入。
                我们理解，儿童输入的指令可能包含他人的个人信息，这种情况下请您务必在提供前取得他人的合法授权，避免造成他人个人信息的不当泄露。儿童不应未经授权提供他人的个人信息。</span
              >
            </p>
            <p>
              5.<span class="bold">AI歌手生成。</span
              >AI歌手是基于儿童主动录制的原始音频，通过生成合成类算法进行训练，生成<span class="bold"
                >基于儿童音色的歌手</span
              >（算法模型）的功能，儿童可选择使用该歌手音色演唱AI写歌生成的歌曲。当儿童生成歌手时，我们会收集儿童填写的歌手艺名和用于生成AI歌手过程中主动录制的<span
                class="bold"
                >原始音频信息</span
              >进行处理，在此过程中，<span class="bold">我们需要调用设备的麦克风权限或录音权限。</span
              >我们会按照法律的要求，对儿童的信息在收集、传输和存储的过程中采取严格的加密措施，以确保儿童的信息安全。我们仅在生成基于儿童音色的歌手的范围内提供音频处理服务，不会提取识别信息，不会用于识别用途。在生成歌手后，系统将自动删除原始音频信息，不予保留。如您不愿我们处理儿童的音频信息，可选择使用AI写歌服务中预设的其他歌手音色，不会影响儿童使用AI写歌服务。
            </p>
            <p>
              您可以<span class="bold">选择公开您创建的歌手音色（声音）、歌手名称</span
              >。在您主动点击“生成”按钮后，我们会将您的歌手音色（声音）、歌手名称开放，供我们及其他“快音”平台内的用户使用。如您不再希望开放，您可以在“我的歌手”内删除您的歌手声音，或通过客服联系我们进行关闭处理。
            </p>
            <p>
              <span class="bold"
                >6.使用快音基本服务功能时。当您所监护儿童使用我们提供的音乐播放、浏览、搜索基本服务时，</span
              >我们会自动收集您所监护儿童对我们服务的使用情况，作为<span class="bold"
                >有关网络日志保存。包括您所监护儿童的：设备品牌（制造商）、设备型号、设备序列号、SSID、BSSID、APP系统版本、移动运营商、浏览器、操作系统版本、屏幕分辨率、屏幕密度、AndroidID、OAID、运营商信息、SIM卡所属国家代码、Wi-Fi状态、WiFi参数、WIFI的信号强度、IP地址、应用及sdk的版本号、获取已安装应用列表。
                在后台运行或静默状态下收集运行中进程信息、应用前后台信息。
              </span>
            </p>
            <p class="bold">
              7.为识别真实参与用户使用目的，以及出于广告和统计目的，我们可能使用您所监护儿童的设备标识符（包括IMEI、IDFA、OAID、AndroidID），广告数据（包括曝光、点击、转化数据），设备信息（包括设备型号、系统语言、屏幕高宽、屏幕方向、MAC地址），应用及使用数据（包括应用包名以及浏览、点击、关注、搜索、分享、收藏操作日志），获取已安装应用，粗略地理位置信息，access_coarse_location信息，VAID，在本产品中向您提供您可能感兴趣的广告，评估、改善我们的广告投放和其他促销及推广活动、商业分析的效果。在您处于后台状态时，我们可能会收集您的OAID、AndroidID、IP地址信息用于更精准的广告推送。
            </p>
            <p>
              <span class="bold"
                >8.基于IP地址的信息展示：我们会收集您所监护儿童的<span class="bold">IP地址</span
                >，基于IP地址对应的城市展示在您所监护儿童的个人资料页和评论中。境内展示到（区、市），境外展示到国家（地区），信息以网络运营商数据为准。</span
              >
            </p>
            <p>
              <i
                >请注意，由于儿童可能使用监护人或其他人员的设备使用“快音”平台服务，本协议提及的所需收集的设备信息与日志信息不完全属于儿童个人信息，我们也无法通过前述信息识别到儿童的具体身份，届时，我们将无法按照儿童个人信息标准对前述信息进行特殊保护。</i
              >
            </p>
          </li>
          <li>
            （六）有关儿童敏感个人信息的提示
            <p>
              除非为了提供服务所必需，我们不会主动要求儿童向我们提供敏感个人信息。<u>请监护人和儿童谨慎并留意儿童个人敏感信息的提供情况，</u>监护人同意儿童相关个人敏感信息我们可以按本政策所述的目的和方式来处理。
            </p>
            <p>如我们需要超出上述范围收集和使用儿童个人信息，我们将再次征得监护人的同意。</p>
            <p>如您选择不提供上述信息，您所监护儿童可能无法注册成为我们的用户或无法享受我们提供的某些产品和/或服务。</p>
          </li>
          <li>
            （七）设备权限调用说明
            <p>
              在您所监护儿童使用我们的产品和/服务时，我们可能需要您所监护儿童使用的设备申请下列与个人信息相关的系统敏感权限：
            </p>
            <p>
              <span class="bold">1.读取/读写外部存储权限：</span
              >当使用Android版本“快音”平台中的头像设置、评论或分享、将文件下载至本地或打开上传本地文件时，可以通过开启存储权限使用或保存图片、视频或文件。若不开启此权限，将无法使用与存储卡读取/读写、缓存相关或个性化推荐的功能，但不影响使用其他服务。
            </p>
            <p>
              <span class="bold">2.相册权限：</span
              >当使用“快音”平台中的头像设置、评论或分享、图像识别服务，在客服页面进行图片、视频上传时，可以通过开通相册权限以便上传设备中的图片或视频。若不开启此权限，将无法使用图片或视频上传相关的功能，但不影响使用其他服务。
            </p>
            <p>
              <span class="bold">3.相机权限：</span
              >当使用Android或IOS版本“快音”平台中的头像设置、评论或分享、图像识别服务时，可以通过开通相机权限以便进行实时拍摄并上传图片或视频进行晒图或分享、设置头像。若不开启此权限，将无法使用拍摄的功能，但不影响使用其他服务。
            </p>
            <p>
              <span class="bold">4.麦克风权限：</span
              >当使用Android或IOS版本“快音”平台中的清唱跟唱服务时，生成AI歌手服务、社区动态发送语音、歌曲语音评论、语音找歌服务时，可以开启麦克风权限点击语音按钮进行录音，以便我们收集您的语音内容并进行必要的处理。若不开启此权限，将无法使用语音输入相关的功能，但不影响使用其他服务。
            </p>
            <p>
              <span class="bold">5.录音权限：</span
              >当您使用生成AI歌手服务、社区动态发送语音、歌曲语音评论、语音找歌服务时，可通过开启设备录音权限进行录音，以便我们收集您的语音内容并进行必要的处理，若不开启此权限，将无法使用录音相关的功能，但不影响使用其他服务
            </p>
            <!--<p>6.读取剪切板权限：在您发布、分享或接收被分享的信息、参加活动情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动功能或服务。特别说明，有些手机等终端设备的系统会提示应用（App）正在读取剪切板，但该系统提示无法区分是否收集剪切板信息，且不排除误报。因此，如果您发现类似提示，请与我们联系，以便我们查找、解决问题。</p>-->
            <p>
              <span class="bold">6.剪切板权限：</span>当您从外部网站或者活动页面下载{{
                appName
              }}并安装使用时，外部网站和活动页面可能会通过剪切板来生成一个邀请码，我们会在您下载开启使用{{
                appName
              }}后读取您剪切板上的这一邀请码用于绑定邀请关系，发放奖励。我们仅在本地识别出剪切板内容属于{{
                appName
              }}邀请码时才会将其上传我们的服务器。除此之外，{{
                appName
              }}客户端不会上传你剪切板的其他信息至我们的服务器。特别说明，有些手机等终端设备的系统会提示应用（App）正在读取剪切板，但该系统提示无法区分是否收集剪切板信息，且不排除误报。因此，如果您发现类似提示，请与我们联系，以便我们查找、解决问题。
            </p>
            <p>
              7.<span class="bold">读取电话状态（读取手机号码权限）、读取通话状态权限（音频焦点信息）</span
              >：在您一键登录时会读取您的手机号码，用于发布音乐作品、<span class="bold">在社区版块发布动态</span
              >时绑定手机号。
              通过读取通话状态在您接听电话时暂停播放音乐。调用前会通过弹窗提醒授权，拒绝不影响其他功能的使用。
            </p>
            <p>
              8.访问账户列表权限：在手机账户系统中添加{{
                appName
              }}账户，用于日历提醒。调用前会通过弹窗提醒授权，拒绝不影响其他功能的使用。
            </p>
            <p>
              9.传感器权限：当您使用创新广告交互功能（包括摇动、扭动、扭转设备）时，我们会调用设备的陀螺仪、加速度、重力、方向、地磁场传感器以识别判断您的设备状态。请您理解，单独的设备传感器数据不涉及任何个人位置信息且无法与其他信息结合用于识别特定自然人身份。
            </p>
            <p>
              10.蓝牙权限：当您使用蓝牙设备连接{{
                appName
              }}时，我们会扫描附近的蓝牙、收集蓝牙设备名称用于开启车载模式场景功能，以上数据仅在设备本地记录处理，不会上传服务器。
            </p>
            <p>11.关联启动：QQ分享、广告落地页跳转。仅在点击相关分享按钮、内容时触发关联启动。</p>
            <p>
              12.自启动：“快音”平台作为音乐互动社区，为了给您提供更加优质的服务，在您同意本政策后，我们会在必要的场景下以合理的频率调用您（Android）设备的自启动功能，以上必要的场景包括：
            </p>
            <ul>
              <li>
                （1）接收消息推送：为避免APP处于关闭或后台运行状态下无法及时收到消息中心的推送，包括：好友一起听歌邀请、平台互动消息、平台活动消息、平台内容消息、用户账户变动消息，我们会通过remotemq、pushservice进程使用自启动功能，用于接收消息推送（push）。
              </li>
              <li>
                （2）音视频播放：作为音乐类APP，为在Android系统上稳定运行，我们会通过remoteplayer进程，维持音乐播放服务的正常运行，避免在系统资源紧张的时候使音乐播放服务被系统回收。
              </li>
              <li class="bold">您可根据自己的意愿，随时在APP的设置页面自主开启/关闭自启动功能。</li>
            </ul>
            <p>
              <span class="bold">13.其他客户端使用的权限的说明和使用场景，见权限使用说明：</span
              ><a :href="PermissionUrl">请点击查看</a>。
            </p>
            <p>如果您希望关闭您之前已开启的设备敏感权限，您可以在设备系统设置进行自主设置。</p>
          </li>
          <li>
            <p class="bold">（八）以下情形中，使用您所监护的儿童的个人信息无需您的同意：</p>
            <p class="bold">1.与个人信息控制者履行法律法规规定的义务相关的；</p>
            <p class="bold">2.与国家安全、国防安全直接相关的；</p>
            <p class="bold">3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p class="bold">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
            <p class="bold">5.出于维护您所监护的儿童或其他个人的生命、财产等重大合法权益但又很难得到您同意的；</p>
            <p class="bold">6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
            <p class="bold">7.根据您的要求签订和履行合同所必需的；</p>
            <p class="bold">
              8.用于维护所提供的产品和/或服务的安全稳定运行所必需的，包括发现、处置产品和/或服务的故障；
            </p>
            <p class="bold">9.为合法的新闻报道所必须的；</p>
            <p class="bold">
              10.出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
            <p class="bold">11.法律法规规定的其他情形。</p>
            <p class="bold">
              请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行匿名化的研究、统计分析和预测，用于改进我们的产品和/或服务（包括使用匿名数据进行机器学习或模型算法训练），按照相关法律法规规定，此类数据已不属于个人信息范畴，因此此类处理后数据的使用无需另行征得您的同意。
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h5 class="bold">三、我们如何使用Cookie和同类技术</h5>
        <p>
          Cookie和设备信息标识等同类技术是互联网中普遍使用的技术。我们可能通过Cookies或同类技术收集您的设备型号、操作系统、唯一设备标识符、登录IP地址信息、以及缓存您的浏览信息、点击信息。我们向您发送Cookies是为了保障产品与服务的安全、高效运转，我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。此外，还可以简化您重复登录的步骤实现登录时无需重复输入用户名及密码信息，并帮助判断您的登录状态以及账户或数据安全。我们承诺，不会将Cookies用于本政策所述目的之外的任何用途。
        </p>
      </section>
      <section>
        <h5>四、我们如何存储及保护儿童的个人信息</h5>
        <p>
          我们会采取加密等技术措施存储儿童个人信息，确保信息安全。此外，我们还会不断加强安装在您设备端的软件的安全能力。我们会收集已安装的应用列表、安装的应用信息或正在运行的进程信息、应用程序的总体运行情况、总体安装使用情况、性能数据、应用来源，以预防病毒、木马恶意程序。
        </p>
        <p>
          我们仅在本政策所述目的所必须期间和法律法规及监管规定的时间限内存储儿童的个人信息。我们依照法律法规的规定，将儿童的个人信息存储于中华人民共和国境内。除法律或相关法规另有约定外，开心音符公司在为提供服务之目的所必需的期间内保留儿童的个人信息，但您要求我们立即删除或注销账户的、或法律法规另有规定的除外。
        </p>
        <p>
          一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储儿童个人信息，并在到期后删除或匿名化处理。您可以自主选择删除播放历史、歌单、订阅等您在使用{{
            appName
          }}服务时提供的和产生的个人信息，如果您选择删除上述信息，我们也将相应删除相应的个人信息。监护人可代替儿童单独进行个人信息的查询访问、删除及修改，为保障儿童个人信息的相关权利我们在产品开发中为监护人提供了对应的操作功能。
        </p>
        <p>
          如果我们终止服务或运营，我们将及时停止继续收集儿童个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并终止服务或运营后对儿童的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
        </p>
      </section>
      <section>
        <h5>五、我们如何委托处理、共享、转让、公开披露儿童的个人信息</h5>
        <p>
          我们充分知晓因违法共享、公开披露个人信息对个人信息主体造成损害时所应承担的法律责任，我们对儿童的信息承担保密义务，不会出售或出租儿童的任何信息，对于儿童的个人信息的一切委托处理、共享、公开披露，我们将严格按照以下条款进行：
        </p>
        <p>（一）委托处理</p>
        <p>
          为了提高效率、降低成本，我们可能会委托第三方专业服务提供商代表我们来处理信息，我们可能会聘请服务提供商为我们提供基础设施技术服务、客服服务、广告服务，内容服务，增值服务。对我们委托处理个人信息的公司、组织和个人，我们会通过书面协议、现场审计等方式要求其遵守严格的保密义务并采取有效的保密措施，要求其只能在委托范围内处理儿童的信息，而不得出于其自身利益使用儿童的信息。
        </p>
        <p>（二）共享</p>
        <p>1.我们不会与其他公司、组织和个人共享您所监护的儿童个人信息，除非单独获得您的明确同意；</p>
        <p>
          2.在用户主动或需要使用特定产品或服务进行实名认证前，我们处理的用户个人信息可能包含能够间接识别到儿童的个人信息。对于此类信息，受限于技术手段，我们将按照<span
            class="bold"
            >《{{ appName }}隐私政策》</span
          >的规定对个人信息实施保护。
        </p>
        <p>3.我们不会与其他公司、组织和个人共享您所监护儿童的个人信息，但以下情况除外：</p>
        <p>（1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享儿童的个人信息。</p>
        <p>
          （2）与授权合作伙伴共享：我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的共享您所监护儿童的个人信息，授权合作伙伴只能接触到其提供的产品或服务所需信息，且我们会要求其不得将此信息用于其他任何目的，与授权合作伙伴约定严格的数据保护措施，并按照法律法规及不低于本政策所要求的标准保护您所监护儿童的个人信息。
        </p>
        <p>（3）目前，我们的授权合作伙伴包括以下类型：</p>
        <p>
          a.广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您所监护儿童的个人身份信息（指可以识别您身份的信息，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
        </p>
        <p>
          b.业务供应商、服务提供商。我们将您所监护儿童的<span class="bold">设备信息、用户ID信息</span
          >发送给支持我们业务的供应商、服务提供商，用于提供SaaS营销或统计版权音乐播放情况等。
        </p>
        <p>
          （4）其他第三方服务。其他第三方服务包括福利活动、现金提现、客服服务、彩铃服务等由第三方提供的服务。儿童用户通过平台跳转到第三方服务，我们可能在获得儿童用户授权同意后向第三方共享儿童用户ID、设备识别码（包括IMSI、IMEI信息）、支付宝账号、微信ID、真实姓名以及其他为儿童用户提供服务所必须的信息，具体共享的信息以实际产品界面呈现和/或对应的授权协议的为准。其他第三方服务是否收集您所监护儿童的个人信息以及收集您所监护儿童的个人信息的范围，由其他第三方服务展示的页面为准。
        </p>
        <p>
          （5）为了使您能够使用上述服务和功能，我们的应用中会嵌入授权合作方的SDK或其他类似的应用程序。我们会对此类接入的SDK或其他类似应用程序进行严格的安全监测，通过协议约束形式以保障数据安全。
        </p>
        <p>
          （6）上述第三方共享的合作方及共享信息清单，以及“快音”平台中接入的第三方SDK或其他类似的应用程序如下：<a
            :href="childSDKUrl"
            >请点击查看</a
          >。
        </p>
        <p>（三）公开披露</p>
        <p>我们仅会在以下情形下，公开披露您所监护的儿童的个人信息：</p>
        <p>1.获得您明确同意后；</p>
        <p>
          2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露儿童的个人信息，在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须具有与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；
        </p>
        <p>
          3.因我们正常管理秩序维护需要，对违规账号、欺诈等严重损害{{
            companyAlias
          }}合法权益的行为进行处罚公告，公告信息主要包括用户账号、用户违规行为。
        </p>
        <p>（四）转让</p>
        <p>我们不会将您所监护的儿童个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>1.在获得您的明确同意后，我们会向其他方转让您所监护儿童的个人信息；</p>
        <p>2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
        <p>
          3.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您所监护儿童的个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p class="bold">（五）共享、公开披露儿童个人信息授权同意的例外情形</p>
        <p class="bold">根据法律法规的规定，在下述情况下，共享、转让、公开披露儿童个人信息无需事先征得您的授权同意：</p>
        <p class="bold">1.与我们履行法律法规规定的义务相关的；</p>
        <p class="bold">2.与国家安全、国防安全直接相关的；</p>
        <p class="bold">3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p class="bold">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p class="bold">5.出于维护您所监护的儿童或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的。</p>
        <p class="bold">6.您自行向社会公众公开的个人信息；</p>
        <p class="bold">7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p class="bold">
          8.根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
      </section>

      <section>
        <h5>六、我们如何保护儿童的个人信息</h5>
        <p>我们非常重视个人信息安全，并采取一切合理可行的措施，保护儿童的个人信息：</p>
        <p>
          1.我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您所监护儿童的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
        </p>
        <p>2.我们已经取得了以下认证：我们的核心业务系统通过了中华人民共和国网络安全等级保护三级认证的备案与评测。</p>
        <p>
          3.如我们停止“快音”平台相关业务，我们将及时停止收集您所监护儿童的个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您所监护儿童的个人信息进行删除或匿名化处理。
        </p>
        <p>
          4.我们采取物理、技术和行政管理安全措施来降低个人信息丢失、误用、非授权访问、披露和更改的风险，包括但不限于传输层数据加密、防火墙和加密存储、物理访问控制以及信息访问授权控制。为此我们设置了安全程序保护您所监护儿童的个人信息不会被未经授权的访问所窃取。
        </p>
        <p>5.我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
        <p>
          6.我们已制定相关的信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。
        </p>
        <p>
          7.互联网环境并非百分之百安全，我们将尽力确保您或您所监护儿童发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您及您所监护儿童告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您及/或您所监护儿童可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息、电话等方式告知您及您所监护儿童。难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您所监护儿童的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p>
          8.互联网并非绝对安全的环境，我们强烈建议您及/或您所监护儿童通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您及/或您所监护儿童发现自己的个人信息泄密，尤其是您及/或您所监护儿童的账户或密码发生泄漏，请您及/或您所监护儿童立即根据本政策中提供的联系方式联络我们，以便我们采取相应措施。但在我们知悉此种情况和在合理时间内采取行动前，我们对此不负任何责任。
        </p>
      </section>

      <section>
        <h5>七、儿童及监护人的权利</h5>
        <p>
          无论您或您所监护的儿童何时使用我们的相关产品和/或服务，我们都会尽一切可能保证您或您所监护的儿童可以顺利访问自己的账户信息。如果这些信息有误，我们会努力提供各种方式来让您或您所监护的儿童快速更新或删除账户内信息。为了使您及您所监护的儿童拥有充分的能力保障隐私和安全，您及您所监护的儿童拥有如下权利：
        </p>
        <p class="bold">1.访问权</p>
        <p>
          您有权访问“快音”平台中您所监护儿童的个人信息。当您通过账号登录“快音”平台后，在“个人中心”功能页面下访问您所监护儿童的个人信息。
        </p>
        <p class="bold">2.更正权</p>
        <p>
          您有权直接通过“快音”平台更正您所监护儿童的个人信息。若您无法操作更正的，可以通过本政策所载的客服联系方式与我们联系，要求我们对错误或不完整的信息做出更正或补充。
        </p>
        <p class="bold">3.撤销权</p>
        <p>改变您授权同意的范围或撤回授权，我们将通过以下方式保障您撤回同意的权利：</p>
        <p>
          （1）您可以通过设备的设置功能，关闭相应设备权限（包括存储空间、麦克风、相机），撤回对“快音”平台获取您设备权限的授权，用户可在“手机设置→权限”中关闭相关隐私授权。
        </p>
        <p>
          （2）您也可通过本政策第十一条联系方式联系我们来撤回您的同意，可发送邮件至<a
            href="mailto:kuaiyinkf@rings.net.cn"
            >【kuaiyinkf@rings.net.cn】</a
          >与我们联系，我们将依照相关法律法规妥善处理您的个人信息，但您撤回同意的行为并不影响我们之前基于您的同意对相关个人信息进行的合法处理。
        </p>
        <p class="bold">4.删除权</p>
        <p>在以下情形中，您可以向我们提出删除您所监护儿童的个人信息的请求：</p>
        <p>（1）处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
        <p>（2）我们停止提供产品或者服务，或者保存期限已届满；</p>
        <p>（3）您撤回处理个人信息的同意；</p>
        <p>（4）我们违反法律、行政法规或者违反与您的约定处理个人信息；</p>
        <p>（5）法律、行政法规规定的其他情形。</p>
        <p>您可参考下面的指引进行操作，此外我们还在本政策尾部放置了联系方式，您的意见将会得到及时的处理。</p>
        <ul>
          <li class="bold">个人头像、昵称、简介等资料信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【头像】中的【编辑】按钮</li>
          <li>3）编辑对应的【头像】【昵称】【简介】即可进行对应的访问或删除及修改操作</li>
          <li class="bold">生日、性别、城市等属性资料信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【头像】中的【编辑】按钮</li>
          <li>3）编辑对应的【生日】【性别】【城市】即可进行对应的访问或删除及修改操作</li>
          <li class="bold">个人下载缓存作品信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【我下载的】找到具体需要访问或删除及修改操作的歌曲</li>
          <li>3）点击对应作品左侧【小封面】后右侧【更多】按钮下滑可进行【删除】操作</li>
          <li class="bold">个人最近播放作品信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【最近播放】后【右上角】三点按钮【清空最近播放】即可进行删除操作</li>
          <li class="bold">个人评论、跟唱内容信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【我的跟唱】找到具体需要访问或删除及修改操作的跟唱评论内容</li>
          <li>3）点击对应跟唱评论内容右侧【三点】按钮即可进行【删除】操作</li>
          <li>4）点击所评论的作品【封面】进入详情页</li>
          <li>5）点击【评论】按钮找到具体需要访问或删除及修改操作的文字评论内容</li>
          <li>6）点击对应文字评论内容右下角【删除】按钮即可进行删除操作</li>
          <li class="bold">个人作品发布内容信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）下滑【作品】找到具体需要访问或删除及修改操作的歌曲</li>
          <li>3）点击对应作品左侧【小封面】后右侧【更多】按钮下滑可进行【删除】操作</li>
          <li class="bold">个人喜欢作品列表信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【喜欢】找到具体需要访问或删除及修改操作的歌曲</li>
          <li>3）点击对应作品左侧【小封面】后右侧【喜欢】可进行取消喜欢操作</li>
          <li class="bold">个人发布歌单信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【我的歌单】找到具体需要访问或删除及修改操作的歌单</li>
          <li>3）点击对应歌单左侧【封面】后右上角【三点】按钮可进行【编辑歌单信息】【删除歌单】操作</li>
          <li>4）点击对应歌单左侧【封面】后下方作品左侧【小封面】后右侧【更多】可进行【删除】移除歌单操作</li>
          <li class="bold">个人关注信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击【关注】找到具体需要访问或取消关注操作的用户</li>
          <li>3）点击对应关注用户右侧的【已关注】按钮可进行【取消关注】操作</li>
          <li class="bold">个人评论点赞信息</li>
          <li>1）点击已评论点赞的作品【封面】进入详情页</li>
          <li>2）点击【评论】按钮找到具体需要访问或取消的评论内容</li>
          <li>3）点击对应点赞评论右侧的【爱心】按钮可进行【取消点赞】操作</li>
          <li class="bold">个人设备缓存信息</li>
          <li>1）点击【我的】或【右上角个人中心页】</li>
          <li>2）点击右上角齿轮图示【设置】按钮</li>
          <li>3）点击【清除缓存】按钮可分别选择【全部】【缓存音乐】【离线音乐】【其他】的自定义一键清除</li>
        </ul>
        <p>
          以上删除请求一旦被响应，除法律法规另有规定要求保留的信息外（如流水记录等），您所监护儿童的个人信息将被及时删除或匿名化处理。当您所监护的儿童在我们的某项及/或全部服务项下的个人信息被删除后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时及时删除这些信息。在满足本政策、用户协议要求及法律法规要求的最短保存期限的情况下，我们将对您所监护儿童的个人信息进行删除或匿名化处理，但这样可能导致我们无法向您所监护的儿童提供相应的服务。
        </p>
        <p class="bold">5.注销权</p>
        <p>除如下情形外，您可通过“快音”平台下的“我的→设置→注销账号”按照指引注销儿童的注册账户：</p>
        <p>
          （1）为配合人民检察院、公安机关、国家安全机关侦查用户使用{{
            appName
          }}产品和/或服务各应用过程中产生的犯罪行为，更好保护其他用户生命财产安全，为配合人民法院查清案情，您所监护儿童的个人信息和账户将被保存。
        </p>
        <p>（2）为遵守法律法规、政策等关于儿童个人信息的保存期限的规定。</p>
        <p>
          请您注意，在您或您所监护儿童申请注销账户后，我们将在法律规定的时间进行审核处理。若注销账号申请通过审核，原账号下的所有用户数据和账户信息且不可恢复；但在特定情形下，如合理必要地履行我们的法律义务、解决争议、防止欺诈与活动福利滥用等情形，我们将在使用者账号注销后保留不可直接识别儿童个人的信息。若您在注销账户时遇到问题，可点击首页导航中的推荐频道-右滑反馈按钮进入用户反馈页面，联系客服进行协助处理，除此之外也可通过发送邮件至【kuaiyinkf@rings.net.cn】来进行协助处理。
        </p>
        <p class="bold">6.获取您所监护儿童的个人信息副本</p>
        <p>
          我们将根据您的书面请求，为您或您所监护的儿童提供以下类型的个人信息副本：您所监护的儿童个人基本信息、身份信息。您可以通过本政策第十一条的联系方式联系我们，可发送邮件至<a
            href="mailto:kuaiyinkf@rings.net.cn"
            >【kuaiyinkf@rings.net.cn】</a
          >与我们联系，我们将在十五天内回复您的请求，向您提供您或您所监护的儿童在我们的服务中的个人信息副本。但请注意，我们为您提供的信息副本仅以我们收集的信息为限。
        </p>
        <p class="bold">7.约束信息系统自动决策</p>
        <p>
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您所监护儿童的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
        </p>
        <p class="bold">8.转移个人信息</p>
        <p>
          若您请求将您所监护的儿童个人信息转移至您指定的个人信息处理者，符合国家网信部门规定条件的，我们将为您提供转移的途径。
        </p>
        <p class="bold">9.响应您的上述请求</p>
        <p>
          （1）如果您无法通过上述方式访问、更正或删除您所监护儿童的个人信息，或您需要访问、更正或删除您在使用{{
            appName
          }}产品和/或服务时所产生的其他个人信息，或您认为我们存在任何违反法律法规或与关于个人信息的收集或使用的约定，您均可通过本政策所载的客服联系方式与我们取得联系。
        </p>
        <p>
          （2）保障安全，您可能需要提供书面请求，或以其他方式证明您及您所监护儿童的身份。我们可能会先要求您验证您自己及您所监护儿童的身份，然后再处理您或您所监护儿童的要求。我们将在十五个工作日内做出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。
        </p>
        <p class="bold">（3）在以下情形中，我们将无法响应您的请求：</p>
        <p class="bold">a.与个人信息控制者履行法律法规规定的义务相关的。</p>
        <p class="bold">b.与国家安全、国防安全直接相关的。</p>
        <p class="bold">c.与公共安全、公共卫生、重大公共利益直接相关的。</p>
        <p class="bold">d.与刑事侦查、起诉、审判和执行判决等直接相关的。</p>
        <p class="bold">e.个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的。</p>
        <p class="bold">f.出于维护您所监护儿童或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的。</p>
        <p class="bold">g.响应您的请求将导致您或您所监护儿童或其他个人、组织的合法权益受到严重损害的。</p>
        <p class="bold">h.涉及商业秘密等。</p>
      </section>

      <section>
        <h5>八、儿童的个人信息如何在全球范围转移</h5>
        <p>原则上，我们在中华人民共和国境内收集和产生的儿童个人信息，将存储在中华人民共和国境内。</p>
        <p>
          但在我们认为必要的情况下，我们可能将儿童的个人信息传递至中国境外。如需跨境传输，我们将会按照相关法律法规的规定履行必要的诚信，并告知您境外接收方的身份、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使法定权利的方式等事项，并取得您的同意，确保您的上述信息得到在中华人民共和国境内足够同等的保护。
        </p>
        <p>
          如您所监护儿童在境外使用我们的产品和/或服务，您或您所监护儿童将接受来自这些管辖区的访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此情况下，我们会确保儿童的个人信息得到中华人民共和国境内足够同等的保护。
        </p>
      </section>

      <section>
        <h5>九、本政策如何更新</h5>
        <p>
          1.
          为了给您或您所监护的儿童提供更好的服务，我们的业务将不时变化，我们会适时对我们的儿童隐私政策进行修订，该等修订构成本隐私政策的一部分。
        </p>
        <p>
          2.
          经您明确同意，我们不会削减您及您所监护的儿童按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        </p>
        <p>
          3
          .对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过手机发送通知，说明隐私政策的具体变更内容）。本政策所指的重大变更包括但不限于：
        </p>
        <p>（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
        <p>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
        <p>（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>（4）您及您所监护的儿童参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
      </section>

      <section>
        <h5>十、如何联系我们</h5>
        <p>如果您对本政策有任何疑问、意见或建议，可以通过如下任一方式联系我们：</p>
        <p>1.加QQ号:1481328251。</p>
        <p>2.添加微信号：Kuaiyin-C。</p>
        <p>3.发送邮件至【kuaiyinkf@rings.net.cn】与我们儿童个人信息保护负责人联系。</p>
        <p>
          4.除此之外，您还可以将相关函件邮寄至下列地址：【中国（四川）自由贸易试验区成都高新区天府三街199号B区16层B3-2、B4、B5、B6单元】
        </p>
        <p>我们将尽快审核所涉问题，并在验证您的身份后，于十五天内进行回复。</p>
      </section>

      <section>
        <h5>十一、争议解决</h5>
        <p>1.如果您认为我们的个人信息处理行为损害了您或您所监护的儿童的合法权益，您可向有关政府部门反映。</p>
        <p>
          2.本政策以及我们处理儿童个人信息事宜引起的争议，您还可以通过向{{
            companyName
          }}住所地有管辖权的人民法院提起诉讼的方式寻求解决。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  import data from './data/child-protection-policy.json';

  export default {
    name: 'ChildProtectionPolicy',
    components: {},
    props: {},
    data() {
      return {
        childSDKUrl: 'https://landing.happyky.cn/child-sdk-list',
        PersonalInfoListUrl: `https://landing.kaixinyf.cn/personal-info-collect/list`,
        PermissionUrl: 'https://landing.happyky.cn/permission-description',
        privacyUrl: `https://landing.happyky.cn/privacy-protocol`,
        tableData: data.tableData,
        option: data.option,
        content: '',
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          [
            'kuaiyintingge',
            { appName: '快音听歌版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          [
            'kuaiyinjisu',
            { appName: '快音极速版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          ['kuaiyinyue', { appName: '快音悦', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          ['kuaiyinfm', { appName: '快音FM', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
        ]),
        companyAlias: '',
        companyName: '',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
        if (app_name == 'kuaiyinyue') {
          const { origin } = window.location;

          window.location.replace(`${origin}/child-protection-policy-kyy`);
          return;
        }
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.privacyUrl += `?app_name=${app_name}`;
      this.childSDKUrl += `?app_name=${app_name}`;
      this.PermissionUrl += `?app_name=${app_name}`;

      this.appName = this.appNameMap.get(app_name).appName;
      this.companyName = this.appNameMap.get(app_name).companyName;
      this.companyAlias = this.appNameMap.get(app_name).companyAlias;
      document.title = `${this.appName}App儿童隐私政策`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }

  h5 {
    margin: 15px 0;
    font-size: 14px;
  }

  p {
    margin: 12px 0;
    font-size: 13px;
  }

  .bold {
    font-weight: bold;
  }

  .center {
    text-align: center;
  }

  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }

  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }

  ul > li > span.bold {
    display: inline;
  }

  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }

  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }

  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .t-head ul li {
    padding: 2px;
    width: 20%;
    text-align: center;
  }

  .t-body {
    background-color: #fff;
  }

  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }

  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }

  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
